.wrapper {
    position: absolute;
    width: 5rem;
    height: 10rem;
    top: -9.5rem;
    right: 3rem;
    transition: top .8s ease 0s;
    animation: bounceInDown 1.2s alternate ease-in-out;
    cursor: pointer;
    transition: var(--transition);
}

.wrapper:hover {
    top: -4rem;
}

.wrapper span {
    visibility: hidden;
    position: relative;
    transition: all 1s ease;
}

.wrapper:hover span {
    visibility: visible;
}

.square {
    width: 100%;
    height: 80%;
    background-color: rgba(217, 161, 40, 255);
    border-right: 50% solid rgba(217, 155, 37, 255);
    background: linear-gradient(to right, rgba(217, 161, 40, 255) 0%, rgba(217, 161, 40, 255) 50%, rgba(217, 155, 37, 255) 50%, rgba(217, 155, 37, 255) 100%);;
}

.square span {
    text-shadow: 0px 0px 6px rgba(66, 68, 90, 1);
    letter-spacing: 0.02rem;
}

.title,
.description {
    color: var(--color-white);
    font-size: 10px;
    display: block;
    text-align: center;
}

.title {
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 15%;
}

.description {
    text-transform: capitalize;
    font-size: 400;
    padding-top: 5%;
}

.mobile__title {
    text-transform: capitalize;
    font-weight: 400;
    padding-top: 50%;
    color: var(--color-white);
    font-size: 12px;
    display: block;
    text-align: center;
}

.hexagon {
    width: 103%;
    left: -1%;
    position: absolute;
    bottom: -1rem;
    height: 50%;
}

.hexagon img {
    width: 100%;
    -webkit-filter: drop-shadow(0px 0px 4px black);
    filter: drop-shadow(0px 0px 4px black);
    z-index: 1;
    overflow: hidden;
}

.shining__placeholder {
    background: transparent;
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    width: 100%;
    height: 120%;
    position: absolute;
    top: 0rem;
    z-index: 2;
    overflow: hidden;
}

.shining__placeholder:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255,255,255,1);
      -webkit-transition: none;
         -moz-transition: none;
              transition: none;
}

.wrapper:hover .shining__placeholder:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);
    
      -webkit-transition: all .6s ease-in-out;
         -moz-transition: all .6s ease-in-out;
              transition: all .6s ease-in-out;
}

.mobile__shining__placeholder__wrapper {
    background: transparent;
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    width: 100%;
    height: 72%;
    position: absolute;
    top: 0rem;
    z-index: 2;
}

.mobile__shining__placeholder {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.05)
    );
    background-image: -moz-linear-gradient(
      to left,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.05)
    );
    background-image: -webkit-linear-gradient(
      to left,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.05)
    );
    animation: shining 9s infinite;
}

@keyframes bounceInDown {
    0% {
       opacity: 0;
       transform: translateY(-2000px);
    }
    60% {
       opacity: 1;
       transform: translateY(10px);
    }
    80% {
       transform: translateY(-10px);
    }
    100% {
       transform: translateY(0);
    }
}

@media screen and (max-width: 1025px) {
    .wrapper {
        right: 0;
    }
}

@media screen and (min-width: 601px) and (max-width: 750px) {
    .wrapper {
       width: 4rem;
    }

    .title,
    .description {
        font-size: 9px;
    }

    .title {
        padding-top: 40%;
    }

    .wrapper:hover {
        top: -4.8rem;
    }

    .shining__placeholder {
        height: 95%;
    }
}

@media screen and (max-width: 600px) {
    .wrapper {
        right: 1rem;
        width: 3rem;
    }

    .wrapper:hover {
        top: calc(-9.5rem - 228px);
    }

    .wrapper:hover span {
        pointer-events: none;
    }

    .mobile__title {
        letter-spacing: .8px;
        font-size: 8px;
        padding-top: 52px;
    }

    .wrapper span {
        visibility: visible;
        position: relative;
        transition: none;
    }
}

@keyframes shining {
    0% {
      left: -550%;
    }
    100% {
      left: 550%;
    }
  }
