@font-face {
  font-family: 'MyWebFont';
  src: url("./assets/Poppins-Regular.woff2") format("woff2"),
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #262626;
  --color-bg-variant: #262626;
  --color-primary: #4242f4;
  --color-primary-variant: rgba(66, 66, 244, 0.8);
  --color-white: #F5F5F5;
  --color-light: rgba(245, 245, 245, 0.8);
  --cookie-baner-bg: #161a1c;
  --cc-separator-cookie-border-color: #222a30;
  --cc-cookie-category-block-bg: #1e2428;
  --div-shadow: 0px 0px 35px -19px rgba(171, 171, 171, 1);
  --transition: all 400ms ease;
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-witdh-sm: 90%;
  --hue: 240;
  --first-color: hsl(var(--hue), 16%, 18%);
  --first-color-alt: hsl(var(--hue), 16%, 12%);
  --title-color: hsl(var(--hue), 8%, 15%);
  --text-color: hsl(var(--hue), 8%, 35%);
  --body-color: hsl(var(--hue), 100%, 99%);
  --container-color: #FFF;
  --body-font: 'MyWebFont', Fallback, sans-serif;
  --big-font-size: 2.5rem;
  --normal-font-size: 1.5rem;
  --small-font-size: 1.3rem;
  --z-modal: 2000;
  --cookie-modal-duration: 0.25s
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  height: 1rem;
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: rgba(217, 217, 227, .8);
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
}

body {
  font-family: 'MyWebFont', Fallback, sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;

  &.modal-open {
    overflow: hidden;
  }
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section>h2,
section>h5 {
  text-align: center;
  color: var(--color-light);
}

section>h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-white);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}
