header {
    height: 100vh;
    padding-top: 4rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    transition: background 0.5s ease;
}

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

.about-certificate-link,
.about-certificate-link:hover {
    cursor: pointer;
    text-decoration: none !important;
}

@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }
}

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}

@media screen and (min-width: 600px) {
    .personal_data {
        font-size: 50px;
        text-transform: uppercase;
        letter-spacing: 4px;
    }
}

.loading-text {
    opacity: 0;
    transform: translateY(20px);
    animation: loadingAnimation 1s forwards;
}

@keyframes loadingAnimation {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.loading-text span {
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
    animation: loadingAnimation 1s forwards;
    animation-delay: calc(var(--animation-delay) * 1s);
}

.loading-text span:nth-child(2) {
    --animation-delay: 0.1;
}

.loading-text span:nth-child(3) {
    --animation-delay: 0.2;
}
