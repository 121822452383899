.service__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.service {
    background: var(--color-bg-variant);
    border: 1px solid var(--color-primary);
    border-radius: 0 0 2rem 2rem;
    height: fit-content;
    transition: var(--transition);
}

.service:hover {
    background: rgba(0, 0, 0, 0.35);
    border-color: var(--color-primary-variant);
    cursor: default;
    box-shadow: var(--div-shadow);
}

.service__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
    color: var(--color-white);
    font-size: 1rem;
    text-align: center;
}

.service__list {
    padding: 2rem;
}

.service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.service__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}

.service__list p {
    font-size: 0.9rem;
}

.certificate {
    background: linear-gradient(90deg, #ffd12c 0%, #ffd856 39%, #ffffff 50%, #ffd754 61%, #ffcf21 100%);
    background-size: 200% 500%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shining 10s linear infinite;
    overflow: hidden;
}

.certificate:hover {
    text-decoration: underline;
    text-decoration-color: #ffd12c;
}


@keyframes shining {
    0% { background-position:  120% 0%; }
    90% { background-position: 120% 0%; }
    100% { background-position: -80% 0%; }
}

/* responsive settings (medium devices) */
@media screen and (max-width: 1024px) {
    .service__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .service {
        height: auto;
    }

    .service__list-icon {
        width: 16px;
        min-width: 16px;
    }
}

@media screen and (max-width: 800px) {
    .service__list-icon {
        width: unset;
        min-width: 11px;
    }
}
  
@media screen and (max-width: 600px) {
    .service__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .service__list {
        padding: 1rem;
    }

    .service__list-icon {
        width: 12px;
        min-width: 12px;
    }
}

@media screen and (max-width: 450px) {
    section#advantages {
        margin: 6rem 3rem;
    }
}