.experience__container {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience__container > div:hover {
    background: rgba(0, 0, 0, 0.35);
    border-color: var(--color-primary-variant);
    cursor: default;
    box-shadow: var(--div-shadow);
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

.experience__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}

@media screen and (min-width: 1024px) {
    .experience__container {
        grid-template-rows: repeat(3, 1fr);
    }

    .experience__container div:nth-child(1) {
        grid-area: 1 / 1 / 3 / 2;
    }

    .experience__container div:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
    }

    .experience__container div:nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;
    }

    .experience__container div:nth-child(4) {
        grid-area: 3 / 1 / 4 / 2;
    }

    .experience__container div:nth-child(5) {
        grid-area: 3 / 2 / 4 / 3; 
    }
}

/* responsive settings (medium devices) */
@media screen and (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;
    }

    .experience__container > div {
        width: 80%;
        padding: 1rem;
        margin: 0 auto;
        height: fit-content;
    }

    .experience__content {
        padding: 1rem;
        grid-template-columns: 1fr;
    }
}

/* responsive settings (small devices) */
@media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
        
    }
    
    .experience__container > div {
        padding: 2rem 1rem;
    }

    #experience {
        margin: 0;
    }
}

@media screen and (min-width: 601px) and (max-width: 700px) {
    .container.experience__container {
        min-width: 355px;
    }

    .experience__container {
        width: 100%;
    }
}
