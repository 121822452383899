.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    height: fit-content;
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: rgba(0, 0, 0, 0.35);
    box-shadow: var(--div-shadow);
}

.portfolio__item.active {
    border-color: var(--color-primary-variant);
    background: rgba(0, 0, 0, 0.35);
}

.portfolio__item.active:hover {
    background: rgba(0, 0, 0, 0.35);
}

.portfolio__item h3 {
    margin: 1.3rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio__description {
    font-size: 14px;
    transition: var(--transition);
}

.tech-stack {
    color: var(--color-primary);
    font-weight: 600;
}

@media screen and (max-width: 1200px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .read-more {
        padding: 0.75rem 1rem;
    }

    .portfolio__item {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    #portfolio {
        margin: 0;
    }
}
