/* Media Common */
.cookie-banner-widget {
    cursor: pointer;
    margin-bottom: 3rem;
    /* cursor: pointer;
    position: fixed;
    width: 3.3rem;
    height: 3.3rem;
    background: #000000ba;
    top: 1rem;
    left: 1rem;
    border: transparent solid;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    opacity: 0.8;
    -webkit-box-shadow: 0px 0px 13px -6px rgba(36, 36, 36, 1);
    -moz-box-shadow: 0px 0px 13px -6px rgba(36, 36, 36, 1);
    box-shadow: 0px 0px 13px -6px rgba(36, 36, 36, 1);
    transition: opacity 0.3s ease; */
 
    /* &:hover {
        opacity: 1;
    }

    div {
        span {
            font-weight: 700;
            color: #bbbbbb;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    } */
    span {
        color: var(--color-bg);
        transition: var(--transition);

        &:hover {
            color: var(--color-white);
        }
    }
}

.cookie-banner-wrapper {
    position: fixed;
    background: var(--cookie-baner-bg);
    bottom: 12px;
    z-index: 998;
    left: 50%;
    transform: translate(-50%);
    border: 1px #272727 solid;
    border-radius: 15px;
    max-width: 500px;

    .banner-title {
        text-align: left;
        margin-inline: 1rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid var(--cc-separator-cookie-border-color);
    }

    .banner-description {
        text-align: left;
        margin-inline: 1rem;
        margin-bottom: 1rem;

        span {
            font-size: 12px;
        }
    }

    .banner-btn-wrapper {
        display: flex;
        gap: 0.5rem;
        margin-bottom: 1rem;
        margin-inline: 1rem;
        justify-content: space-between;

        > div {
            display: flex;
            gap: 0.5rem;
        }

        .btn {
            &.read-more {
                margin-left: 10px;

                &.settings {
                    background-color: transparent;

                    &:hover {
                        background-color: var(--color-white);
                        border-color: transparent;
                        color: var(--color-bg);
                    }
                }
            }
        }
    }
}

.cookie-modal-wrapper {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--cookie-baner-bg);
    max-width: 680px;
    border: 1px solid transparent;
    border-radius: 15px;
    z-index: 1000;
    max-height: 90vh;
    overflow-y: scroll;

    .cookie-header {
        align-items: center;
        border-bottom: 1px solid;
        padding: 1em 1.4em;
        display: flex;
        justify-content: space-between;
        border-color: var(--cc-separator-cookie-border-color);

        .close {
            cursor: pointer;
            font-weight: 700;
        }
    }

    .content {
        padding: 1em 1.4em;

        .subtitle {
            text-align: left;
        }

        .description {
            margin-top: 0.85rem;
            padding-top: 0;
            text-align: left;

            span {
                font-size: 0.8rem;
            }
        }

        .groups-container {
            margin-top: 2rem;

            .groups-item {
                border-top: none;
                margin-bottom: 0.375rem;
                overflow: hidden;
                position: relative;
                z-index: 0;

                .tab {
                    background: var(--cc-cookie-category-block-bg);
                    border: 2px solid var(--cc-separator-cookie-border-color);
                    border-radius: 10px;
                    align-items: center;
                    display: flex;
                    position: relative;
                    justify-content: space-between;
                    cursor: pointer;
                    padding: 1rem 1.4rem;
                    
                    .title {
                        padding-top: 0;
                        font-weight: 400;
                        font-size: 0.8rem;
                        text-transform: capitalize;
                        display: flex;
                        align-content: center;
                        align-items: center;

                        > svg {
                            margin-right: 15px;
                            font-size: 15px;
                        }

                        &.expanded {
                            > svg {
                                transform: rotate(180deg);
                            }
                        }
                    }


                    .container {
                        margin: 0;
                        display: flex;
                        width: auto;
                        justify-content: flex-end;

                        &.restricted {
                            user-select: none;
                            pointer-events: none;
                        }
                    }
                      
                      .switch {
                        display: inline-block;
                        height: 34px;
                        position: relative;
                        width: 60px;
                      }
                      
                      .switch input {
                        display: none;
                      }
                      
                      .slider {
                        background-color: #ccc;
                        bottom: 0;
                        cursor: pointer;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        transition: .4s;
                      }
                      
                      .slider:before {
                        background-color: #fff;
                        bottom: 4px;
                        content: "";
                        height: 26px;
                        left: 4px;
                        position: absolute;
                        transition: .4s;
                        width: 26px;
                      }
                      
                      input:checked + .slider {
                        background-color: var(--color-primary);
                      }
                      
                      input:checked + .slider:before {
                        transform: translateX(26px);
                      }
                      
                      .slider.round {
                        border-radius: 34px;
                      }
                      
                      .slider.round:before {
                        border-radius: 50%;
                      }
                }

                .content {
                    text-align: left;
                    font-size: 0.8rem;
                    background: var(--cc-cookie-category-block-bg);
                    border-color: var(--cc-separator-cookie-border-color);
                    border-style: solid;
                    border-bottom-width: 2px;
                    border-left-width: 2px;
                    border-right-width: 2px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    overflow: hidden;
                    transition: margin-top 0.4s ease, opacity 0.4s ease, padding 0.4s ease, height 0.4s ease;
                    margin-top: -10px;
                    opacity: 1;
                    z-index: -1;
                    position: relative;
                    /* max-height: unset; */
                    height: auto;

                    &.hidden {
                        text-align: left;
                        font-size: 0.8rem;
                        background: var(--cc-cookie-category-block-bg);
                        border-color: var(--cc-separator-cookie-border-color);
                        border-style: solid;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        padding: 0 1.4em;
                        margin-top: -85px;
                        opacity: 0;
                        z-index: -1;
                        position: relative;
                        /* max-height: 70px; */
                        cursor:unset;
                        height: 90px;
                    }
                }
            }
        }
    }

    .cookie-footer {
        padding:  1em 1.4em;
        border-top: 1px solid var(--cc-separator-cookie-border-color);
        display: flex;
        justify-content: space-between;

        > div {
            display: flex;
            gap: 0.5rem
        }

        .btn {
            &.read-more {
                &.save {
                    margin-left: 10px;
                    background-color: transparent;

                    &:hover {
                        background-color: var(--color-white);
                        border-color: transparent;
                        color: var(--color-bg);
                    }
                }
            }
        }
    }
}

.cookie-modal-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.65);;
    z-index: 999;
    left: 0;
    top: 0;
    opacity: 1;
    visibility: visible;
    transition: opacity var(--cookie-modal-duration) ease,visibility var(--cookie-modal-duration) ease,transform var(--cookie-modal-duration) ease;
}

@media (max-width: 1024px) {
    .cookie-banner-widget {
        bottom: 12rem;
    }
}

@media (max-width: 392px) {
    .cookie-modal-wrapper {
        width: 95vw;
    }

    .cookie-footer {
        flex-direction: column;
        
        > div {
            margin-bottom: 10px;
        }

        .btn {
            &.read-more {
                &.save {
                    margin-left: 0 !important;
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 415px) {
    .cookie-banner-wrapper {
        width: 90vw;
    }
    .banner-btn-wrapper {
        flex-direction: column;
    }
    .btn {
        &.read-more {
            &.settings {
                margin-left: 0 !important;
                width: 100%;
            }
        }
    }
}
