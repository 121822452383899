.Portfolio_Placeholder_Wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #1f1f38;
  overflow: hidden;
  border-radius: 5px;
}

.Profile_Placeholder_Wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Testimonials_Placeholder_Wrapper {
  width: 100%;
  height: 100%;
}

.Hexagon_Placeholder_Wrapper {
  background: #FED700;
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  width: 100%;
  height: 115%;
  position: absolute;
  top: 0rem;
}

.Testimonials_Placeholder_Content,
.Portfolio_Placeholder_Content,
.Profile_Placeholder_Content,
.Hexagon_Placeholder_Content {
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  background-image: -moz-linear-gradient(
    to left,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  background-image: -webkit-linear-gradient(
    to left,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  animation: loading 1s infinite;
}

.About_Placeholder_Wrapper {
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  top: 0;
}

.About_Placeholder_Content {
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  overflow: hidden;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  background-image: -moz-linear-gradient(
    to left,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  background-image: -webkit-linear-gradient(
    to left,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  animation: loading 1s infinite;
}

@keyframes loading {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 750px) {
  .Hexagon_Placeholder_Wrapper {
    height: 96%;
  }
}

@media screen and (max-width: 600px) {
  .Hexagon_Placeholder_Wrapper {
    height: 72%;
  }
}
