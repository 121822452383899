.portfolio__item-image {
    position: relative;
    border-radius: 1.5rem;
    overflow: hidden;
}

#main_image_container {
    transition: all 0.5s ease;
}

#main_image_container:hover {
    margin-top: 6rem;
    border-radius: 12rem 11rem 0 0;
}

#main_image_container:hover #main_image_image {
    transform: scale(1.01) translateY(-8%);
    -webkit-filter: drop-shadow(20px 20px 20px #181818);
    filter: brightness(107%) drop-shadow(20px 20px 20px #181818);
}

#main_image_image {
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: brightness(100%) drop-shadow(5px 5px 5px #222);
    transition: all 0.5s ease;
}
